
#page {
    display: flex;
    flex-direction: row;
}

#topics {
    width: 20%;
    height: 100vh;
    text-align: center;
}

#statistics {
    width: 50%;
    color: green;
    height: 100vh;
}

.topicButton, .periodButton {
    border: 1px solid black;
    border-radius: 3px;
    padding: 3px;
    margin: 2px 1px;
}

.topicButton.selected {
    background: rgba(100, 220, 100, 0.2);
}

#statistics {
    width: 100vw;
}

.members-list {
    width: 100%;
}